import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import pivision from "../../Assets/Projects/pivision-thumb.png";
import backprop from "../../Assets/Projects/backprop-thumb.png";
import mc from "../../Assets/Projects/mc-thumb.png";
import topics from "../../Assets/Projects/topics-thumb.png";
import nn from "../../Assets/Projects/nn-thumb.png";
import cudacourse from "../../Assets/Projects/cudacourse-thumb.png";
import fccllms from "../../Assets/Projects/fccgpt-thumb.png";
import mojo from "../../Assets/Projects/mojo-thumb.png";
import gptds from "../../Assets/Projects/gpt-ds-thumb.jpeg";
import securitypi from "../../Assets/Projects/secpi-thumb.png";
import chatpi from "../../Assets/Projects/chatpi-thumb.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Work </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cudacourse}
              isBlog={false}
              title="CUDA Programming Course - High-Performance Computing with GPUs"
              description="My free course with a focus on Deep Learning applications and theory with NVIDIA GPUs."
              ghLink="https://github.com/Infatoshi/cuda-course"
              demoLink="https://www.youtube.com/watch?v=86FAWCzIe_4&ab_channel=freeCodeCamp.org"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fccllms}
              isBlog={false}
              title="Course: Build a Large Language Model from Scratch"
              description="My free course on building state-of-the-art chatbots from math, stats, and tensors."
              ghLink="https://github.com/Infatoshi/fcc-intro-to-llms"
              demoLink="https://www.youtube.com/watch?v=UU1WVnMk4E8&t="
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pivision}
              isBlog={false}
              title="Build a Raspberry Pi Voice Assistant (w/ Vision)"
              description="A Raspberry Pi based voice assistant utilizes the ElevenLabs TTS API, Google Speech Recognition, and Groq for Fast Inference (plus additional features to make it more robust)."
              ghLink="https://github.com/Infatoshi/llama-va"
              demoLink="https://www.youtube.com/watch?v=iBLGuH4yz68&t=8s&ab_channel=Elliotcodes"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={backprop}
              isBlog={false}
              title="explaining backpropagation until i go bananas"
              description="A lecture that explains scalar to tensor-level backpropagation intuitively on a whiteboard."
              ghLink=""
              demoLink="https://www.youtube.com/watch?v=0dbihoMRuyg&t=970s&ab_channel=Elliotcodes"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={topics}
              isBlog={false}
              title="Master Every Topic...Ever"
              description="Download all the textbooks you'll ever need across all university topics with a simpler python script."
              ghLink="https://github.com/Infatoshi/txtscrpr"
              demoLink="https://www.youtube.com/watch?v=woYmjEQ4QoI&t=2s&ab_channel=Elliotcodes"
            />
          </Col>
          

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mc}
              isBlog={false}
              title="Minecraft Meets AI: Quick Setup Tutorial"
              description="Setup MineRL locally to get a Pre-trained Minecraft Agent to do tasks."
              ghLink="https://github.com/Infatoshi/vpt"
              demoLink="https://www.youtube.com/watch?v=raM-SKDonrI&ab_channel=Elliotcodes"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mojo}
              isBlog={false}
              title="Course: Intro to the Mojo Programming Language"
              description="A free course on the Mojo programming language. This course is designed to teach you the basics of the Mojo programming language."
              ghLink="https://github.com/Infatoshi/intro-to-mojo"
              demoLink="https://www.youtube.com/watch?v=5Sm9IVMet9c"
            />
          </Col>


          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nn}
              isBlog={false}
              title="How to Build a Neural Network in Python from SCRATCH (In-Depth Tutorial)"
              description="Lets build a neural network from scratch in Python. We'll build a simple neural net w/ PyTorch."
              ghLink="https://github.com/Infatoshi/nn-14min"
              demoLink="https://www.youtube.com/watch?v=Gk_5I6YG_Jw&t=754s&ab_channel=Elliotcodes"              
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={securitypi}
              isBlog={false}
              title="Security Pi"
              description="A Raspberry Pi based security system that uses a camera mixed with computer vision algorithms to detect motion and send an alert to the user via discord pings."
              ghLink="https://github.com/Infatoshi/security-pi"
              demoLink="https://www.youtube.com/watch?v=tvpH8p3Crno"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gptds}
              isBlog={false}
              title="DiscordGPT"
              description="A JavaScript-based Discord bot that chats with users and contains conversation context."
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>  */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
